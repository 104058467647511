import React from 'react';
import "../../styles/footer.css"

const Footer = () => {

    return (
        <footer>
            <h1>Thanks for visiting!</h1>
        </footer>
    )
}

export default Footer
